import React, { useState, useEffect } from "react"

const useIsSmall = () => {
  const [isSmall, setIsSmall] = useState(false)
  // useEffect(() => {
  //   if (swiperSlides.length > 1) {
  //     initSwiper();
  //   }
  // }, [swiperSlides]);
  const checkDeviceSize = e => {
    if (e.matches) {
      setIsSmall(true)
    } else {
      setIsSmall(false)
    }
  }
  useEffect(() => {
    const mql = window.matchMedia("(max-width: 640px)")
    mql.addListener(checkDeviceSize)

    setIsSmall(mql.matches)
  }, [])
  return isSmall
}

export default useIsSmall
