import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Hero from "../components/Hero"
import Layout from "../layouts"
import { pageMargins, remCalc } from "../styles/utils"
import LetterAnimation from "../animations/LetterAnimation"
import { typography, color } from "../styles/variables"
import ReportContent from "../components/pages/Foreword/ReportContent"
import animationData from "../animations/DESKTOP_MOBILE/01-Intro.json"
import mobileAnimationData from "../animations/DESKTOP_MOBILE/01-Intro_MOBILE.json"
import useIsSmall from "../hooks/useIsSmall"

const StyledForeword = styled.div`
  width: 100%;
  ${pageMargins};
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  @media only screen and (max-width: 640px) {
    flex-direction: column-reverse;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  & .foreword_copy {
    width: 50%;
    position: relative;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
    a {
      color: black;
      cursor: pointer;
    }
    & p {
      margin: 2rem 0;
      font-size: 1.25rem;
      line-height: ${remCalc(30)};
      font-family: ${typography.type.secondary};
      &:first-child {
        margin-top: 0;
      }
      & strong {
        line-height: ${remCalc(33)};

        font-family: ${typography.type.primary};
        font-size: 1.5rem;
        @media only screen and (max-width: 1024px) {
          font-size: 1.25rem;
          line-height: 30px;
        }
        @media only screen and (max-width: 640px) {
          font-size: 18px;
          line-height: 27px;
          font-weight: normal;
          /* font-family: ${typography.type.secondary}; */
        }
      }
    }
  }
  & .ceo_image {
    width: 50%;
    padding: 0 5rem;
    @media only screen and (max-width: 640px) {
      width: 75%;
      padding: 0;
    }
    & p {
      font-size: ${remCalc(18)};
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      padding: 0 2rem;
    }
    @media only screen and (max-width: 640px) {
      padding: 0rem;
      & .ceo-text {
        margin-bottom: 2rem;
        margin-top: 0.2rem;
      }
    }
  }
`

const contact = ({ location }) => {
  const isSmall = useIsSmall()
  const data = useStaticQuery(graphql`
    query {
      ceoImage: file(relativePath: { eq: "ceo-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <Hero
        bg="black"
        animationData={animationData}
        mobileAnimationData={mobileAnimationData}
        mobileColors={color.header.foreword}
        location={location.pathname}
        contact
      >
        <div className="contact_name">
          <p className="contact_large-para">Chris Hogwood</p>
          <p>MD, Corporate Affairs</p>
          <p>
            <a href="mailto:chris.hogwood@landsec.com">
              chris.hogwood@landsec.com
            </a>
          </p>
        </div>

        <a className="contact_landsec-link" href="https://landsec.com">
          Return to landsec.com
        </a>
      </Hero>
    </Layout>
  )
}

export default contact
